<template>
    <div class="tab-body">
        <el-scrollbar style="height: 240px; overflow-y: auto">
            <div 
                v-for="(item, index) in listGroup.list" 
                :key="item.id"
                class="item"
                @click="groupDetail(item)"
            >
            <div v-if="!item._randomization" class="collapse-content-img" :style="{'background-color': item.iconBackgroundColor}">
                <img v-if="item.iconLink" :src="item.iconLink" alt="icon" style="width: 100%;height: 100%">
            </div>
            <img v-else class="randomization" :src="item.iconLink" />
            <div class="title">{{ item.formName }}</div>
                <span 
                    v-if="item._add"
                    class="el-icon-circle-plus"
                    @click.stop="addHandlerClick(item)"
                >
                </span>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
    export default {
        name: "TabBody",
        props: {
            listGroup: {
                type: Object,
                default: () => {
                    return {};
                }
            }
        },
        methods: {
            /**
             * @Description: 新增到常用审批单
             * @Author: 武东超
             * @Date: 2024-09-04 14:19:22
             */            
            addHandlerClick(data) {
                this.$eventDispatch('addHandlerClick', data);
            },
            groupDetail(data) {
                this.$eventDispatch('groupDetail', data);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .tab-body {
        /deep/ .el-scrollbar__view {
            display: flex;
            flex-wrap: wrap;
        }
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .item {
            width: 218px;
            height: 56px;
            display: flex;
            align-items: center;
            position: relative;
            flex-shrink: 0;
            background: rgba(255,255,255,0.5);
            border-radius: 4px;
            border: 1px solid #E7E7E7;
            margin: 10px 16px 10px 0;
            cursor: pointer;
            .collapse-content-img {
                width: 32px;
                height: 32px;
                padding: 5px;
                box-sizing: border-box;
                margin-left: 12px;
                margin-right: 12px;
                border-radius: 10px;
                background-color: #f5f7fa;
            }
            .randomization {
                width: 32px;
                height: 32px;
                margin-left: 12px;
                margin-right: 12px;
                border-radius: 10px;
            }
            .title {
                width: 128px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 18px;
                display: -webkit-box; 
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; 
                overflow: hidden; 
                text-overflow: ellipsis; 
                white-space: normal; 
            }
            .el-icon-circle-plus {
                width: 16px;
                height: 16px;
                position: absolute;
                top: -8px;
                right: -8px;
                color: #3C7FFF;
            }
        }
        .item:hover {
            border-color: #3C7FFF;
        }
    }
</style>