<template>
    <div class="approvals-item" @click="groupDetail">
        <div v-if="!data._randomization" class="collapse-content-img" :style="{'background-color': data.iconBackgroundColor}">
            <img v-if="data.iconLink" :src="data.iconLink" alt="" style="width: 100%;height: 100%">
        </div>
        <img v-else class="randomization" :src="data.iconLink" />
        <div class="title">{{ data.formName }}</div>
        <span
            v-if="commonStatus === 'add'" 
            class="el-icon-remove" 
            @click.stop="removeHandlerClick"
        >
        </span>
    </div>
</template>
<script>
    export default {
        name: "ApprovalsItem",
        props: {
            commonStatus: {
                type: String
            },
            data: {
                type: Object,
                default: {}
            }
        },
        methods: {
            removeHandlerClick() {
                this.$eventDispatch('removeHandlerClick', this.data);
            },
            groupDetail() {
                this.$eventDispatch('groupDetail', this.data);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .approvals-item {
        width: 218px;
        height: 54px;
        display: flex;
        align-items: center;
        position: relative;
        background: rgba(255,255,255,0.5);
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        flex-shrink: 0;
        margin-right: 16px;
        cursor: pointer;
        .collapse-content-img {
            width: 32px;
            height: 32px;
            padding: 5px;
            box-sizing: border-box;
            margin-left: 12px;
            margin-right: 12px;
            border-radius: 10px;
            background-color: #f5f7fa;
        }
        .randomization {
            width: 32px;
            height: 32px;
            margin-left: 12px;
            margin-right: 12px;
            border-radius: 10px;
        }
        .title {
            width: 128px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 18px;
            display: -webkit-box; 
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden; 
            text-overflow: ellipsis; 
            white-space: normal;
        }
        .el-icon-remove {
            width: 16px;
            height: 16px;
            position: absolute;
            top: -8px;
            right: -8px;
            color: #FC6060;
        }
    }
    .approvals-item:hover {
        border-color: #3C7FFF;
    }
</style>