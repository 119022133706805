<template>
    <div class="approvals-list">
        <approvals-item
            v-for="(item, index) in list"
            :key="index"
            :data="item"
            :commonStatus="commonStatus"
        >
        </approvals-item>
    </div>
</template>
<script>
    import ApprovalsItem from './Item.vue';

    export default {
        name: "ApprovalsList",
        components: {
            ApprovalsItem
        },
        props: {
            commonStatus: {
                type: String
            },
            list: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .approvals-list {
        width: 100%;
        height: 74px;
        display: flex;
        box-sizing: border-box;
        padding-top: 8px;
        overflow-x: auto;
    }
</style>