<template>
    <div class="status-item">
        <common-title>
            <div class="complex">
                <div class="title-info">
                    <div class="icon">
                        <img class="icon-image" :src="data.icon" alt="icon">
                    </div>
                    <div class="title">{{ data.title }}</div>
                    <span v-if="data.nums" class="nums">{{ data.nums }}</span>
                </div>
                <div class="more-group" @click="handlerMore(data)">
                    <span class="more">更多</span>
                    <span class="el-icon-arrow-right"></span>
                </div>
            </div>
        </common-title>
        <div class="status-body">
            <ul class="status-list" v-if="data.list && data.list.length > 0">
                <li  
                    class="status-subitem"
                    v-for="(subItem, subIndex) in data.list"
                    @click="formDetail(subItem, data)"
                >
                    <div class="title">{{ subItem.dataTitle }}</div>
                    <div :class="`type-${subItem.approveStats}`">{{ getType(subItem.approveStats) }}</div>
                </li>
            </ul>
            <div v-else class="no-data">
                <div class="default-img-group">
                    <img class="default-img" :src="defaultImg" alt="defaultImg">
                    <span>暂无内容～</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import CommonTitle from '../Title.vue';
    import RecordsList from '@/libs/pageListOptimal.js';
    import {
        mapMutations,
        mapState
    } from 'vuex';
    export default {
        name: "StatusItem",
        components: {
            CommonTitle
        },
        props: {
            activeName: {
                type: String
            },
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        computed: {
            defaultImg() {
                return require('@/assets/images/coworkingOAApproval/default.png');
            }
        },
        methods: {
            ...mapMutations(['setOAQueryData']),
            getType(type) {
                switch (type) {
                    case '0':
                        return '未开始';
                    case '1':
                        return '处理中';
                    case '2':
                        return '审批通过'
                    case '3':
                        return '审批拒绝'
                    case '4':
                        return '已撤销'
                    case '5':
                        return '审批通过'
                }
            },
            /**
             * @Description: 跳转详情页
             * @Author: 武东超
             * @Date: 2024-09-04 17:09:56
             * @param {*} data
             */            
            formDetail(data, bigData) {
                console.log(data.id,'data-id');
                this.setOAQueryData({
                    id: data.id, // 申请id
                    dataTitle: data.dataTitle, // 申请title
                    backData: {
                        name: this.$route.name,
                        query: {
                            activeName: this.activeName
                        }
                    }
                })
                //存储滚动条高度
                let recordsList =  new RecordsList()
                // recordsList.set(this.$route.name, {
                //     tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
                // })
                // 跳转到详情页时，存储检索条件，用于从详情页返回后保留检索值和检索结果
                // localStorage.setItem('listQuery', JSON.stringify(this.listQuery))
                this.$router.push({
                    name: 'CoworkingCampusOAOAApprovalApplyDetail',
                    query: {
                        activeName: '1'
                    }
                })
            },
            /**
             * @Description: 查看更多
             * @Author: 武东超
             * @Date: 2024-09-04 17:49:36
             */            
            handlerMore(data) {
                this.$eventDispatch('handlerMore', data)
            }
        }

    }
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 1440px) {
        .status-item {
            width: 296.5px !important;
        }
    }

    .status-item {
        width: calc(25% - 10.5px);
        height: 302px;
        background: #FFFFFF;
        border-radius: 4px;
        flex-shrink: 0;
        margin-right: 14px;
        .complex {
            width: 100%;
            height: 54px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
            border-radius: 4px 4px 0px 0px;
            box-sizing: border-box;
            padding: 0px 10px 0px 15px;
            .title-info {
                display: flex;
                align-items: center;
                flex-direction: row;
                .icon {
                    display: flex;
                    align-items: center;
                    .icon-image {
                        width: 28px;
                        height: 28px;
                    }
                }
                .title {
                    margin-left: 7px;
                    height: 25px;
                }
                .nums {
                    width: 26px;
                    height: 16px;
                    line-height: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 12px;
                    text-align: center;
                    background-color: #FC6060;
                    border-radius: 8px;
                    color: #FFFFFF;
                }
            }
            .more-group {
                display: flex;
                align-items: center;
                cursor: pointer;
                .more {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                .el-icon-arrow-right {
                    color: #333333;
                }
            }   
            
        }
        .status-body {
            width: 100%;
            height: 248px;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .status-list {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                padding: 0 10px;
                .status-subitem {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 19px;
                    background-color: #F9F9F9;
                    border-radius: 4px;
                    margin-bottom: 8px;
                    cursor: pointer;
                    .title {
                        width: 249px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 15px;
                    }
                    .type-0 {

                    }

                    .type-1 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #3C7FFF;
                        text-wrap: nowrap;
                    }

                    .type-2 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #46CC95;
                        text-wrap: nowrap;
                    }

                    .type-3 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FC6060;
                        text-wrap: nowrap;
                    }

                    .type-4 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #F5B942;
                        text-wrap: nowrap;
                    }

                    .type-5 {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #46CC95;
                        text-wrap: nowrap;
                    }
                }
                .status-subitem:hover {
                    background-color: #F0F0F0;
                }
            }
            .no-data {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .default-img-group {
                    display: flex;
                    flex-direction: column;
                    .default-img {
                        width: 96px;
                        height: 68px;
                    }
                    span {
                        display: block;
                        text-align: center;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #999999;
                        line-height: 36px;
                    }
                }
            }
        }
    }
    .status-item:last-child {
        margin-right: 0px !important;
    }
</style>