<template>
    <div class="tab-approvals">
        <el-row type="flex" align="middle">
            <el-tabs class="tab" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                    v-for="(item, index) in scrollList"
                    :key="item.groupId"
                    :label="item.label" 
                    :name="item.groupId"
                >
                </el-tab-pane>
            </el-tabs>
        </el-row>
        <tab-body :listGroup="listGroup"></tab-body>
    </div>
</template>
<script>
    import TabBody from './TabBody.vue';
    export default {
        name: "TabApprovals",
        components: {
            TabBody
        },
        props: {
            tabActiveName: {
                type: String,
                default: () => {
                    return ''
                }
            },
            scrollList: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        computed: {
            // activeName() {
            //     console.log(this.tabActiveName,'tabActiveName')
            //     return this.tabActiveName;
            // },
            listGroup() {
                return this.scrollList.filter(item => this.activeName == item.groupId)[0];
            }
        },
        data() {
            return {
                activeName: this.tabActiveName
            }
        },
        methods: {
            handleClick(tab) {
                console.log(tab, this.activeName, 'tab')
            }
        },
        watch: {
            'tabActiveName': {
                handler() {
                    console.log(this.tabActiveName,'this.tabActiveName')
                    this.activeName = this.tabActiveName;
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .tab-approvals {
        .tab {
            width: 100%;
            overflow: hidden;
        }
        /deep/ .el-tabs__active-bar {
            bottom: 1px;
        }
        /deep/ .el-tabs__nav-wrap::after {
            height: 1px;
        }
    }
</style>