<template>
    <div class="status-list">
        <status-item
            v-for="(item, index) in list"
            :key="item.id"
            :data="item"
            :activeName="activeName"
            v-hasPermi="item.vHasPermi"
        >
        </status-item>
    </div>
</template>
<script>
    import StatusItem from './Item.vue';
    export default {
        name: "StatusApprovals",
        components: {
            StatusItem
        },
        props: {
            activeName: {
                type: String
            },
            list: {
                type: Array,
                default: []
            }
        }
    }
</script>
<style lang="scss" scoped>
    .status-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: auto;
    }
</style>